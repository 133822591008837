
import './App.scss';
import Header from '../Header/Header'
import Introduction from '../Introduction/Introduction';


function App() {
  return (
    <main className='main'>
      <Introduction />
    </main >
  );
}



export default App;
