import './Introduction.scss';

function Introduction() {
  return (
    <section className='introdaction'>

      <h1 className='name'>hello</h1>
      <h2 className='last-name'>Boris Kim</h2>
      <p className='last-name'>Frontend developer</p>
      <p className='description'>The website is under development.</p>

    </section>
  );
}

export default Introduction;
